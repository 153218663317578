import { plainToClass, Type } from 'class-transformer';
import { BaseCandidate } from '../BaseCandidate';

export class DcrRowLog {
  @Type(/* istanbul ignore next */ () => Number)
  id!: number;
  importFirstName!: string | null;
  importLastName!: string | null;
  @Type(/* istanbul ignore next */ () => Boolean)
  startPaySaved!: boolean;
  @Type(/* istanbul ignore next */ () => Boolean)
  orderSaved!: boolean;
  errors!: string | null;
  @Type(/* istanbul ignore next */ () => Number)
  row!: number;
  @Type(/* istanbul ignore next */ () => BaseCandidate)
  startpayCandidate!: BaseCandidate | null;

  constructor(partial?: Partial<DcrRowLog>) {
    if (partial) {
      Object.assign(this, plainToClass(DcrRowLog, partial));
    }
  }
}
