import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { DcrRowLogTableData } from './DcrRowLogTableData';
import { TableDataProvider } from '../../models/TableDataProvider';
import { DcrRowLog } from '../../../../models/Entities/Imports/DcrRowLog';
import { SearchDcrRowLogDto } from '../../../../models/Dtos/searchDcrRowLogDto';

export class DcrRowLogDataProvider extends TableDataProvider<
  DcrRowLogTableData,
  DcrRowLog,
  SearchDcrRowLogDto
> {
  protected transformer = (t: DcrRowLog): DcrRowLogTableData =>
    new DcrRowLogTableData(t);

  protected queryHandler = async (
    searchDto: SearchDcrRowLogDto
  ): Promise<SearchResponseDto<DcrRowLog>> => {
    const results: SearchResponseDto<DcrRowLog> = await this.store.dispatch(
      'materializedModule/fetchWithSelectParameters',
      { path: 'dcr-row-log', query: searchDto, model: DcrRowLog }
    );
    return results;
  };
}
