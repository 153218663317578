
import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import IconComponent from '@/components/IconComponent.vue';
import AsyncTableComponent from '@/components/Table/AsyncTableComponent.vue';
import QuickFilterComponent from '@/components/Table/QuickFilterComponent.vue';
import TableFilterComponent from '@/components/Table/TableFilterComponent.vue';
import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import { DcrRowLogTableData } from '../../components/Table/DCR/DcrRowLog/DcrRowLogTableData';
import { DcrRowLog } from '../../models/Entities/Imports/DcrRowLog';
import { SearchDcrRowLogDto } from '../../models/Dtos/searchDcrRowLogDto';
import { dcrRowLogTableConfigBuilder } from '../../components/Table/DCR/DcrRowLog/DcrRowLogTableConfigBuilder';
import { DcrRowLogDataProvider } from '@/components/Table/DCR/DcrRowLog/DcrRowLogDataProvider';
import { DcrImportLog } from '@/models/Entities/Imports/DcrImportLog';
import { SearchDcrImportLogDto } from '@/models/Dtos/searchDcrImportLogDto';
import { formatDate, formatTime } from '@/services/formatService';
import Routes from '@/router/Routes';
import FetchMixin from '@/mixins/FetchMixin';

@Component<DcrRowLogPage>({
  components: {
    AsyncTableComponent,
    TableFilterComponent,
    QuickFilterComponent,
    LinkElementComponent,
    IconComponent
  }
})
export default class DcrRowLogPage extends Mixins(Vue, FetchMixin) {
  routes = Routes;

  @Prop()
  readonly dcrImportId!: number;

  importDateTime = '';

  searchService: TableSearchService<
    DcrRowLogTableData,
    DcrRowLog,
    never,
    SearchDcrRowLogDto
  > | null = null;

  async created(): Promise<void> {
    await this.queryImportDateTime();
    this.searchService = new TableSearchService<
      DcrRowLogTableData,
      DcrRowLog,
      never,
      SearchDcrRowLogDto
    >(
      this.$store,
      new DcrRowLogDataProvider(this.$store),
      { dcrImportId: this.dcrImportId, relations: ['startpayCandidate'] },
      { defaultSortProperty: 'row' },
      dcrRowLogTableConfigBuilder,
      {
        filters: [],
        quickFilters: []
      }
    );
    await this.searchService.queryData();
  }

  async queryImportDateTime(): Promise<void> {
    const query: SearchDcrImportLogDto = {
      dcrImportId: this.dcrImportId
    };
    const importedLog = await this.getOne({
      path: 'dcr-import-log',
      query,
      model: DcrImportLog
    });
    if (importedLog.createDateTime) {
      const date = formatDate(importedLog.createDateTime);
      const time = formatTime(importedLog.createDateTime);
      this.importDateTime = `${date} at ${time}`;
    }
  }
}
