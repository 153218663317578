import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { DcrRowLog } from '../../../../models/Entities/Imports/DcrRowLog';
import { DisplayNameUtil } from '../../../../util/displayName';

export class DcrRowLogTableData {
  entityId: number;
  name: string;
  startPaySaved: boolean;
  orderSaved: boolean;
  errors: string | null;
  row: number;
  startPayCandidate: BaseCandidate | null;

  constructor(dcrRow: DcrRowLog) {
    this.entityId = dcrRow.id;
    this.name = DisplayNameUtil.getFullName(
      dcrRow.importFirstName,
      dcrRow.importLastName
    );
    if (dcrRow.startpayCandidate) {
      this.name = DisplayNameUtil.getFullName(
        dcrRow.startpayCandidate.identificationInformationFirstname,
        dcrRow.startpayCandidate.identificationInformationLastname
      );
    }
    this.startPaySaved = dcrRow.startPaySaved;
    this.orderSaved = dcrRow.orderSaved;
    this.errors = dcrRow.errors;
    this.row = dcrRow.row + 1;
    this.startPayCandidate = dcrRow.startpayCandidate;
  }
}
