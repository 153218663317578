import { SearchDcrRowLogDto } from '@/models/Dtos/searchDcrRowLogDto';
import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import TextWithColorElementComponent from '@/components/Table/Elements/TextWithColorElementComponent.vue';
import IconTableComponent from '@/components/Table/Elements/IconTableComponent.vue';
import { TableConfigBuilder } from '../../models/TableConfigBuilder';
import { DcrRowLogTableData } from './DcrRowLogTableData';
import { DcrRowLog } from '../../../../models/Entities/Imports/DcrRowLog';

// DCR
export const dcrRowLogTableConfigBuilder: TableConfigBuilder<
  DcrRowLogTableData,
  DcrRowLog,
  never,
  SearchDcrRowLogDto
> = (): ITableConfiguration<DcrRowLogTableData, DcrRowLog, never> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'No.',
        render: {
          componentRef: TextWithColorElementComponent,
          componentProps: (
            rowData: DcrRowLogTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowData.row,
              color: 'black'
            };
          }
        },
        sortKey: 'row',
        defaultPinned: true
      },
      {
        columnTitle: 'Name',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowData: DcrRowLogTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowData.name,
              linkTo: rowData.startPayCandidate // TODO: check candidate first
                ? {
                    name: Routes.APPLICANT_DETAILS,
                    params: { id: rowData.startPayCandidate.id }
                  }
                : {}
            };
          }
        },
        sortKey: 'importLastName'
      },
      {
        columnTitle: 'Added to Start Pay',
        render: {
          componentRef: IconTableComponent,
          componentProps: (
            rowData: DcrRowLogTableData
          ): Record<string, unknown> => {
            return {
              iconState: rowData.startPaySaved,
              iconIfTrue: 'circleCheck',
              iconIfFalse: 'circleCross',
              width: 16,
              height: 16
            };
          }
        },
        sortKey: 'startPaySaved'
      },
      {
        columnTitle: 'Orders Imported',
        render: {
          componentRef: IconTableComponent,
          componentProps: (
            rowData: DcrRowLogTableData
          ): Record<string, unknown> => {
            return {
              iconState: rowData.orderSaved,
              iconIfTrue: 'circleCheck',
              iconIfFalse: 'circleCross',
              width: 16,
              height: 16
            };
          }
        },
        sortKey: 'orderSaved'
      },
      {
        columnTitle: 'Errors',
        render: {
          componentRef: TextWithColorElementComponent,
          componentProps: (
            rowData: DcrRowLogTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowData.errors || '',
              color: 'black'
            };
          }
        },
        sortKey: 'errors'
      }
    ]
  };
};
